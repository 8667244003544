<template>
  <div class="my-orders-page">
    <div class="readable_content">
      <h4>Billing History</h4>
      <mcr-loading-indicator v-if="ordersLoadingState" :loading="true"></mcr-loading-indicator>
      <my-orders-empty-state v-else-if="noOrders"></my-orders-empty-state>
      <div v-else>
        <order-item :order="order" v-for="order in ordersListState" :key="order.created_at"></order-item>
        <base-pagination :meta="ordersMetaState" @onSwitchPage="onSwitchPage"></base-pagination>
        <div class="supplemental help-text">
          Need help regarding your purchase? <a href="mailto:support@mychinaroots.com">Contact our support team</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasePagination from '@common/elements/BasePagination.vue';
import {mapGetters} from 'vuex';

import MyOrdersEmptyState from '@/components/modules/myOrders/MyOrdersEmptyState';
import OrderItem from '@/components/modules/myOrders/OrderItem';

export default {
  created() {
    this.getOrders();
  },
  computed: {
    ...mapGetters(['ordersListState', 'ordersLoadingState', 'ordersMetaState']),
    noOrders() {
      return this.ordersListState.length === 0;
    },
  },
  methods: {
    getOrders(page) {
      this.$store.dispatch('fetchOrdersAction', {page: page || 1});
    },
    onSwitchPage(page) {
      this.getOrders(page);
    },
  },
  name: 'MyOrdersPage',
  components: {MyOrdersEmptyState, BasePagination, OrderItem},
};
</script>

<style lang="scss" scoped>
.my-orders-page {
  .order-item {
    padding: 10px 0 15px;
    border-bottom: 1px solid $divider-line-color;
  }
  .help-text {
    margin-top: 40px;
  }
}
</style>
